import { Helmet } from "react-helmet";
import "./styles.css";
import React, { useState, useEffect } from "react";
import { Chart } from "chart.js/auto";
import Sentiment from "sentiment";
import natural from "natural";
import "./Page.css";
import axios from "axios";
import nlp from "compromise";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  faDownload,
  faChartPie,
} from "@fortawesome/free-solid-svg-icons";

const API_URL = "https://outrageous-pear-purse.cyclic.app";

function Home() {
  const [videoUrl, setVideoUrl] = useState("");
  const [progress, setProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [sentimentResults, setSentimentResults] = useState([]);
  const [topKeywords, setTopKeywords] = useState([]);
  const [videoId, setVideoId] = useState("");
  const sentiment = new Sentiment();
  const commonStopwords = natural.stopwords;
  const [commentsFetched, setCommentsFetched] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [transcription, setTranscription] = useState('');
  const [videoStatistics, setVideoStatistics] = useState({
    likeCount: 0,
    viewCount: 0,
    commentCount: 0,
  });
  useEffect(() => {
    const fetchVideoStatistics = async () => {
      try {
        const apiKey = "AIzaSyCWvZVNbX-aT6XCvopLcAJRS_kDlj3A4LY";
        const videoInfoUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${apiKey}&part=statistics`;
        const videoInfoResponse = await axios.get(videoInfoUrl);
        const statistics = videoInfoResponse.data.items[0].statistics;
        console.log("statistics",statistics)
        setVideoStatistics({
          likeCount: parseInt(statistics.likeCount),
          viewCount: parseInt(statistics.viewCount),
          commentCount: parseInt(statistics.commentCount),
        });
      } catch (error) {
        console.error("Error fetching video statistics:", error);
      }
    };

    if (videoId) {
      fetchVideoStatistics();
    }
  }, [videoId]);
  const handleGetComments = async () => {
    try {
      setProgress(50);
      console.log(progress, isDownloading);
      setIsDownloading(true);
      const videoId = extractVideoId(videoUrl);
  
      if (videoId) {
        setVideoId(videoId);
      }
      const response = await fetch(`${API_URL}/comments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ youtubeLink: videoUrl }),
      });
  
      if (response.ok) {
        setCommentsFetched(true);
  
        const comments = await response.json();
        setProgress(100);
        setIsDownloading(false);
        setShowDownloadButton(true);
  
        const analyzedComments = comments.map((comment) => {
          const sentimentScore = sentiment.analyze(comment).score;
          let sentimentStatus = "";
        
          const isQuestioning = comment.includes("?") || /(\bwho\b|\bwhat\b|\bwhen\b|\bwhere\b|\bwhy\b|\bhow\b)/.test(comment);
        
          if (sentimentScore > 0) {
            sentimentStatus = "Positive";
          } else if (sentimentScore < 0) {
            sentimentStatus = "Negative";
          } else if (isQuestioning) {
            sentimentStatus = "Questioning";
          } else {
            sentimentStatus = "Neutral";
          }
        
          return { sentimentStatus, isQuestioning, comment };
        });
        
  
        setSentimentResults(analyzedComments);
        const existingChart = Chart.getChart("sentimentChart");
        if (existingChart) {
          existingChart.destroy();
        }
        const keywordMap = {};
        analyzedComments.forEach((result) => {
          const comment = result.comment;
          const keywords = extractKeywordsFromComment(comment);
  
          keywords.forEach((keyword) => {
            if (!keywordMap[keyword]) {
              keywordMap[keyword] = 0;
            }
            keywordMap[keyword]++;
          });
        });
  
        const sortedKeywords = Object.keys(keywordMap).sort(
          (a, b) => keywordMap[b] - keywordMap[a]
        );
        const topKeywordsWithCounts = sortedKeywords
          .slice(0, 3)
          .map((keyword) => ({
            keyword,
            count: keywordMap[keyword],
          }));
        setTopKeywords(topKeywordsWithCounts);
  
        const sentimentCounts = {
          Positive: 0,
          Neutral: 0,
          Questioning: 0,
          Negative: 0
        };
        analyzedComments.forEach((result) => {
          sentimentCounts[result.sentimentStatus]++;
          if (result.isQuestioning) {
            sentimentCounts["Questioning"]++;
          }
        });
  
        const chartData = {
          labels: Object.keys(sentimentCounts),
          datasets: [
            {
              label: "Sentiment Counts",
              data: Object.values(sentimentCounts),
              backgroundColor: [
                "rgba(145, 182, 230, 0.2)",
                "rgba(145, 182, 230, 0.2)",
                "rgba(145, 182, 230, 0.2)",
                "rgba(145, 182, 230, 0.2)",
              ],
              borderColor: ["rgba(145, 182, 230, 1)"],
              borderWidth: 1,
            },
          ],
        };
        
        const options = {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              type: "category",
              ticks: {
                font: {
                  size: 12,
                },
              },
            },
            y: {
              beginAtZero: true,
              grid: {
                display: true,
                color: "rgba(255, 255, 255, 0.2)",
              },
            },
          },
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              color: "rgba(255, 255, 255, 0.7)",
              font: {
                size: 12,
                weight: 'bold'
              },
              formatter: function(value) {
                return parseInt(value);
              }
            },
          },
        };
        
        const ctx = document.getElementById("sentimentChart").getContext("2d");
        new Chart(ctx, {
          type: "bar",
          data: chartData,
          options: options,
          plugins: [ChartDataLabels]
        });
        
      } else {
        console.error("Error fetching comments", response);
      }
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };
  
  function extractVideoId(url) {
    const match = url.match(/[?&]v=([^&]+)/);
    if (match) {
      return match[1];
    }
    return null;
  }

const extractKeywordsFromComment = (comment) => {
  const cleanedComment = comment
    .replace(/<\/?[^>]+(>|$)/g, "")
    .replace(/\d+/g, "");

  const doc = nlp(cleanedComment);
  const terms = doc.terms().out("array");

  const keywords = terms.filter((term) => {
    const lowercaseTerm = term.toLowerCase();
    return !commonStopwords.includes(lowercaseTerm) && isNaN(lowercaseTerm);
  });

  return keywords;
};


  const handleAnalyze = async () => {
    try {
      const mockSentimentResults = ["Positive", "Neutral","Questioning","Negative"];
      setSentimentResults(mockSentimentResults);
    } catch (error) {
      console.error("Error analyzing sentiment:", error);
    }
  };

  const handleDownload = () => {
    try {
      setDownloading(true);
      if (sentimentResults.length === 0) {
        console.error("No sentiment results to download");
        return;
      }

      const csvData = sentimentResults
        .map(
          (result) =>
            `"${result.sentimentStatus}","${cleanComment(result.comment)}"`
        )
        .join("\n");

      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
      const url = URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = "insight.csv";

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(url);
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
      console.error("Error initiating download:", error);
    }
  };
  const handleDownloadVideo = async () => {
    try {
      const response = await fetch(`${API_URL}/download/${videoId}`);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "video.mp4");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading video:", error);
    }
  };
  const cleanComment = (comment) => {
    const cleanedComment = comment
      .replace(/<\/?[^>]+(>|$)/g, "")
      .replace(/"/g, '""');
    return cleanedComment;
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />
        <title>My Social Insights</title>
        <link rel="icon" type="image/x-icon" href="/logo.png" />
        <a href="http://www.freepik.com">Designed by starline / Freepik</a>
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css"
          rel="stylesheet"
          type="text/css"
        />
        <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" rel="stylesheet"/>

        <link
          href="https://fonts.googleapis.com/css?family=Lato:300,400,700,300italic,400italic,700italic"
          rel="stylesheet"
          type="text/css"
        />
        <link href="css/styles.css" rel="stylesheet" />
      </Helmet>
      <body>
        <nav class="navbar navbar-light bg-light static-top">
          <div class="container">
            <a class="navbar-brand" href="#!">
              My Social Insights
            </a>
            <a class="btn btn-primary" href="#signup">
              Get started
            </a>
          </div>
        </nav>
        <header className="masthead">
        <div className="container position-relative">
  <div className="row justify-content-center">
    <div className="col-xl-8">
      <div className="text-center text-white">
        <h1 className="mb-5">
          Explore, Capture, and Analyze YouTube Comments
        </h1>
        <form className="form-subscribe" id="contactForm" data-sb-form-api-token="API_TOKEN">
          <div className="input-group">
            <input type="text" className="form-control form-control-lg" id="videoUrlInput" placeholder="Search for a YouTube video..." value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)} />
            <button className="btn btn-primary" type="button" onClick={handleGetComments}>
              <i className="fas fa-search"></i>
            </button>
          </div>
          <div className="invalid-feedback" id="submitErrorMessage">Error sending message!</div>
        </form>
      </div>
    </div>
  </div>
  <br></br>

  <div className="row justify-content-center">
    {videoId && (
      <div className="col-xl-8">
        <div className="d-flex justify-content-center">
          <iframe title="video" className="embed-responsive-item" src={`https://www.youtube.com/embed/${videoId}`} allowFullScreen style={{ width: "100%", height: "400px" }}></iframe>
        </div>
      </div>
    )}
  </div>
</div>

  <div className="container">
    <div className="row justify-content-center">
      <div className="col-xl-8">
        <div className="card-body">
          {commentsFetched ? (
         <>
         <div className="sentiment-analysis-section my-4">
           <h2 className="text-white">Sentiment Analysis Results</h2>
           <div className="chart-container">
             <canvas id="sentimentChart" className="custom-chart"></canvas>
           </div>
         </div>
       
         <div className="d-flex justify-content-center mt-4">
           {downloading && <p className="text-primary">Your video is being downloaded...</p>}
           <button
             className="btn btn-danger mx-2"
             id="downloadVideoBtn"
             style={{ display: showDownloadButton ? "block" : "none" }}
             onClick={handleDownloadVideo}
           >
             <FontAwesomeIcon icon={faDownload} /> Download Video (MP4)
           </button>
           <button
             className="btn btn-success mx-2"
             id="downloadBtn"
             style={{ display: showDownloadButton ? "block" : "none" }}
             onClick={handleDownload}
           >
             <FontAwesomeIcon icon={faDownload} /> Download Comments
           </button>
         </div>
       
         <div className="top-keywords-section my-4">
           <h2 className="text-white mb-3">Top Keywords</h2>
           <ul className="list-group">
             {topKeywords.map((item, index) => (
               <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                 <span className="keyword">{item.keyword}</span>
                 <span className="badge bg-secondary rounded-pill">{item.count} times</span>
               </li>
             ))}
           </ul>
         </div>
       
         <div className="video-statistics-section my-4">
           <h2 className="text-white">Video Statistics</h2>
           <div className="row">
             <div className="col">
               <div className="card text-white bg-primary mb-3">
                 <div className="card-body">
                   <h5 className="card-title">Views</h5>
                   <p className="card-text">{videoStatistics.viewCount}</p>
                 </div>
               </div>
             </div>
             <div className="col">
               <div className="card text-white bg-success mb-3">
                 <div className="card-body">
                   <h5 className="card-title">Likes</h5>
                   <p className="card-text">{videoStatistics.likeCount}</p>
                 </div>
               </div>
             </div>
             <div className="col">
               <div className="card text-white bg-info mb-3">
                 <div className="card-body">
                   <h5 className="card-title">Comments</h5>
                   <p className="card-text">{videoStatistics.commentCount}</p>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </>
       
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  </div>
</header>

        <section class="features-icons bg-light text-center">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <div class="features-icons-item mx-auto mb-4 mb-lg-0 mb-lg-3">
          <div class="features-icons-icon d-flex justify-content-center">
            <i class="bi bi-download h4 text-primary"></i>
          </div>
          <h5 class="mb-2">Download Video (MP4)</h5>
          <p class="lead fs-6 mb-0">
            Easily download MP4 videos with high quality.
          </p>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="features-icons-item mx-auto mb-4 mb-lg-0 mb-lg-3">
          <div class="features-icons-icon d-flex justify-content-center">
            <i class="bi bi-chat-left-text h4 text-primary"></i>
          </div>
          <h5 class="mb-2">Download Comments</h5>
          <p class="lead fs-6 mb-0">
            Download comments associated with videos.
          </p>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="features-icons-item mx-auto mb-0 mb-lg-3">
          <div class="features-icons-icon d-flex justify-content-center">
            <i class="bi bi-graph-up h4 text-primary"></i>
          </div>
          <h5 class="mb-2">Analyze Sentiment</h5>
          <p class="lead fs-6 mb-0">
            Analyze sentiment from user comments or reviews associated with videos.
          </p>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="features-icons-item mx-auto mb-0 mb-lg-3">
          <div class="features-icons-icon d-flex justify-content-center">
            <i class="bi bi-bar-chart h4 text-primary"></i>
          </div>
          <h5 class="mb-2">Get Video Statistics</h5>
          <p class="lead fs-6 mb-0">
            Access comprehensive statistics and analytics for your videos.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>  


        <section class="showcase">
          <div class="container-fluid p-0">
            <div class="row g-0">
              <div
                className="col-lg-6 order-lg-2 text-white showcase-img"
                style={{ backgroundImage: "url('/img/youtube.jpg')" }}
              ></div>
              <div class="col-lg-6 order-lg-1 my-auto showcase-text">
                <h2>Effortless Video Downloads</h2>
                <p class="lead mb-0">
                Seamlessly download high-quality MP4 videos with ease. Enjoy convenience and quality in every download, enhancing your viewing experience.
                </p>
              </div>
            </div>
            <div class="row g-0">
              <div
                class="col-lg-6 text-white showcase-img"
                style={{ backgroundImage: "url('/img/comments.jpg')" }}
              ></div>
              <div class="col-lg-6 my-auto showcase-text">
                <h2>Streamlined Comment Access</h2>
                <p class="lead mb-0">
                Easily access and download comments associated with your videos. Capture valuable feedback and insights to inform your content strategy.
                </p>
              </div>
            </div>
            <div class="row g-0">
              <div
                class="col-lg-6 order-lg-2 text-white showcase-img"
                style={{ backgroundImage: "url('/img/analyse.jpg')" }}
              ></div>
              <div class="col-lg-6 order-lg-1 my-auto showcase-text">
                <h2>Insightful Sentiment Analysis</h2>
                <p class="lead mb-0">
                Dive deep into user comments and reviews with sentiment analysis. Gain valuable insights into audience reactions and sentiments to refine your content approach.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <section class="testimonials text-center bg-light">
          <div class="container">
            <h2 class="mb-5">What people are saying...</h2>
            <div class="row">
              <div class="col-lg-4">
                <div class="testimonial-item mx-auto mb-5 mb-lg-0">
                  <img
                    class="img-fluid rounded-circle mb-3"
                    src="/testimonials-1.jpg"
                    alt="..."
                  />
                  <h5>Margaret E.</h5>
                  <p class="font-weight-light mb-0">
                    "This is fantastic! Thanks so much guys!"
                  </p>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="testimonial-item mx-auto mb-5 mb-lg-0">
                  <img
                    class="img-fluid rounded-circle mb-3"
                    src="/img/testimonials-2.jpg"
                    alt="..."
                  />
                  <h5>Fred S.</h5>
                  <p class="font-weight-light mb-0">
                    "Bootstrap is amazing. I've been using it to create lots of
                    super nice landing pages."
                  </p>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="testimonial-item mx-auto mb-5 mb-lg-0">
                  <img
                    class="img-fluid rounded-circle mb-3"
                    src="/img/testimonials-3.jpg"
                    alt="..."
                  />
                  <h5>Sarah W.</h5>
                  <p class="font-weight-light mb-0">
                    "Thanks so much for making these free resources available to
                    us!"
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section class="call-to-action bg-dark text-white text-center py-5" id="signup">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6">
        <h2 class="display-4 mb-4">Coming Soon</h2>
        <p class="lead mb-4">We're excited to announce that our project is coming soon for various platforms! </p>
        <p class="lead mb-4">Stay tuned</p>
        <div class="social-icons">
        <a href="#/" class="social-icon text-white me-3"><i class="fab fa-instagram"></i></a>
<a href="#/" class="social-icon text-white me-3"><i class="fab fa-facebook"></i></a>
<a href="#/" class="social-icon text-white me-3"><i class="fab fa-twitter"></i></a>
<a href="#/" class="social-icon text-white"><i class="fab fa-tiktok"></i></a>

        </div>
      </div>
    </div>
  </div>
</section>


        <footer class="footer bg-light">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 h-100 text-center text-lg-start my-auto">
                <ul class="list-inline mb-2">
                  <li class="list-inline-item">
                    <a href="#!">About</a>
                  </li>
                  <li class="list-inline-item">⋅</li>
                  <li class="list-inline-item">
                    <a href="#!">Contact</a>
                  </li>
                  <li class="list-inline-item">⋅</li>
                  <li class="list-inline-item">
                    <a href="#!">Terms of Use</a>
                  </li>
                  <li class="list-inline-item">⋅</li>
                  <li class="list-inline-item">
                    <a href="#!">Privacy Policy</a>
                  </li>
                </ul>
                <p class="text-muted small mb-4 mb-lg-0">
                  &copy; My Social Insights 2024. All Rights Reserved.
                </p>
              </div>
              <div class="col-lg-6 h-100 text-center text-lg-end my-auto">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item me-4">
                    <a href="#!">
                      <i class="bi-facebook fs-3"></i>
                    </a>
                  </li>
                  <li class="list-inline-item me-4">
                    <a href="#!">
                      <i class="bi-twitter fs-3"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#!">
                      <i class="bi-instagram fs-3"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </body>
    </>
  );
}

export default Home;
