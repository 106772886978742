import './App.css';
import Home from './Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./custom.css"

function App() {
  return (
    <Home></Home>
  );
}

export default App;
